import Api from '@/services/graphql';
import CollectionsApi from '@/services/graphql/collections';
import CollectionProductsApi from '@/services/graphql/collectionProducts';
import ComparisonApi from '@/services/graphql/comparison';
import LibrariesApi from '@/services/graphql/libraries';
import ProductsApi from '@/services/graphql/products';
import ProjectViewsApi from '@/services/graphql/projectViews';
import SubscriptionsAPI from '@/services/graphql/subscriptions';
import WorkspaceApi from '@/services/graphql/workspace';
import {
  subscribeForCollectionsAccess,
  subscribeToCollectionFollow,
  subscribeToCollectionUnfollow,
} from '@/services/graphql/collectionsSubscriptions';
import { subscribeOnProductUpdate } from '@/services/graphql/productsSubscriptions';

import PRODUCT_HEADERS from '@/constants/productHeaders';
import { SORT_TOP } from '@/constants/scheduleViews';
import { COLLECTION_SORT_ITEMS } from '@/constants/sortable';
import { NEW_FEATURES_AND_FEEDBACK } from '@/constants/urls';
import {
  IS_STORE_ROOT, TYPE_READONLY, TYPE_CREATE, GROUP_FOLLOWED,
} from '@/constants';
import {
  COLLECTIONS_WORKSPACE_GROUP,
  COLLECTIONS_PUBLISHED_GROUP,
  COLLECTIONS_FOLLOWED_GROUP,
} from '@/constants/collectionsList';
import {
  COLLECTION, PROMISE_STATUSES, UPLOAD_STATUSES, WS_PAGE,
} from '@/constants/cores';
import {
  FOLLOW_COLL_SUB_TITLE,
  UNFOLLOW_COLL_SUB_TITLE,
} from '@/constants/subscriptions';
import {
  ON_PRODUCT_UPDATE, COLLECTION_ACCESS,
} from '@/constants/userPermissions';

import {
  lastUniqById, getURLSearchParams, sortHelper,
} from '@/utils';
import { parseGetRows } from '@/utils/manageGetRows';
import SearchProductsKeys from '@/utils/searching/SearchProductsKeys';

import router from '@/router';
import {
  cloneDeep,
  uniqBy,
} from 'lodash';

export const actions = {
  async getSharedLink({ commit, dispatch }, { collectionId, accessToken }) {
    try {
      const { data } = await CollectionsApi.getCollectionSharedLink({
        collectionId,
        accessToken,
      });
      const finalResponse = data.response;
      commit('setSharedLink', finalResponse);
      const { workspaceId } = finalResponse;
      commit('Workspace/setActiveWorkspaceId', workspaceId, IS_STORE_ROOT);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async getPageQuickViewLink({ commit, dispatch }, { collectionId, accessToken, workspaceId: paramWorkspaceId, pageId }) {
    try {
      const { data } = await CollectionsApi.getCollectionSharedLink({
        collectionId,
        accessToken,
        workspaceId: paramWorkspaceId,
        pageId,
      });
      const finalResponse = data.response;
      commit('setSharedLink', finalResponse);
      return finalResponse;
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async getWorkspaceSharedLink({ commit, dispatch }, { workspaceId, pageId }) {
    try {
      const { data } = await CollectionsApi.getWorkspaceSharedLink({
        workspaceId,
        pageId,
      });
      const finalResponse = data.response;
      commit('setSharedLink', finalResponse);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async resetPageQuickViewLink({ dispatch }, { collectionId, workspaceId }) {
    try {
      const { data } = await CollectionsApi.resetCollectionSharedLink({
        collectionId,
        workspaceId,
      });
      return data.response?.url;
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async resetSharedLink({ dispatch }, { pageId, workspaceId }) {
    try {
      const { data } = await CollectionsApi.resetWorkspacePageSharedLink({
        pageId,
        workspaceId,
      });
      return data.response?.url;
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async getCollectionsSchema({ commit, rootState }, info) {
    try {
      const accessToken = rootState.accessToken;
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await CollectionsApi.collectionsSchema({
        collectionId: info?.collectionId,
        libraryId: info?.libraryId,
        workspaceId,
        ...(accessToken && {
          accessToken,
        }),
      });
      commit('setCollectionsSchema', data.response);
      const { read_only_fields } = data.response;
      commit('ProjectDetailsTableSchedule/setReadOnlyFields', read_only_fields, IS_STORE_ROOT);
    } catch (err) {
      console.log('getCollectionsSchema err', err);
    }
  },
  async getCollectionsViews({ commit, rootState, state }, info) {
    try {
      const accessToken = rootState.accessToken;
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await CollectionsApi.collectionsViews({
        collectionId: info?.collectionId,
        libraryId: info?.libraryId,
        workspaceId,
        ...(accessToken && {
          accessToken,
        }),
      });
      let views = data.response;
      views = views.map(view => {
        const existingView = state.collectionsViews.find(view => view?.id);
        const sortingData = !existingView?.sortingData
          ? sortHelper(view.id, view?.sortingField || '', SORT_TOP)
          : existingView?.sortingData;
        return {
          ...view,
          sortingData,
        };
      });
      commit('setCollectionsViews', views);
    } catch (err) {
      console.log('getCollectionsViews err', err);
    }
  },
  async updateCollectionSharedLink({ dispatch }, params) {
    try {
      await CollectionsApi.updateCollectionSharedLink(params);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  async updatePageQuickViewLink({ dispatch }, params) {
    try {
      await CollectionsApi.updateCollectionSharedLink(params);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  setCollectionsViewsSorting({ state, commit }, { sortingData }) {
    const mappedViews = state.collectionsViews.map(view => {
      if (view.id == sortingData.viewId) {
        return {
          ...view,
          sortingData,
        };
      }
      return view;
    });
    commit('setCollectionsViews', mappedViews);
  },
  async parseCollectionListNext({ commit, state, dispatch, rootGetters }, {
    data,
    mutationName,
    nextTokenForRequest,
    paginationMutation,
    group,
    list,
    libraryId,
    isToAutocomplete,
    pageId = null,
    isWsPage = false,
    useIcreaseLimit = false,
    allowGetAllItems,
    filteredListOfCollection,
    accessToken,
    showSpinner,
    comingFromRoute,
  }) {
    const showProgressBar = !rootGetters['FeatureFlags/useLazyLoading'];
    try {
      const { data: dataCollection = [], ...rest } = data;
      await commit(`${mutationName}`, nextTokenForRequest ? lastUniqById([
        ...state[list], ...dataCollection,
      ]) : dataCollection);
      await commit(`${paginationMutation}`, rest);
      if (useIcreaseLimit && showProgressBar) {
        dispatch('manageProgressLinear', {
          collectionGroups: filteredListOfCollection,
        });
      }
      if ((isToAutocomplete || useIcreaseLimit || !dataCollection?.length) && rest.nextToken) {
        await dispatch('getCollectionsList', {
          nextTokenForRequest: rest.nextToken,
          libraryId,
          isToAutocomplete,
          collectionGroup: group,
          pageId,
          isWsPage,
          allowGetAllItems,
          filteredListOfCollection,
          accessToken,
          showSpinner,
          comingFromRoute,
        });
      }
    } catch (err) {
      console.log('err parseCollectionListNext', err);
    }
  },
  async getCustomLibraryCollectionsList({ getters, commit, state, dispatch, rootGetters }, {
    nextToken,
    libraryId,
    workspaceId,
    isAutocomplete = false,
    loadAll = true,
  }) {
    const isLastAccessed = rootGetters['UserProfile/isSortedCollectionByLastAccessed'];
    const requestName = isLastAccessed ? 'listResentlyCollections' : 'listCustomLibraryCollections';
    try {
      const { data } = await LibrariesApi[requestName]({
        libraryId,
        workspaceId,
        nextToken,
      });
      const mappedResData = isLastAccessed ? data.response.data : data.response;
      const {
        getAppropriateCollectionsGroupsToAutocomplete: forAutocomplete,
        getAppropriateCollectionsGroups: forListing,
      } = getters;
      const getAppropriateCollectionsGroups = isAutocomplete ? forAutocomplete : forListing;
      const [collections, newNextToken] = [mappedResData, mappedResData?.nextToken];
      getAppropriateCollectionsGroups.forEach(({ group, mutationName, list, paginationMutation }) => {
        const appropriateCollections = collections[group];
        if (!appropriateCollections) {
          return;
        }
        const newCollections = collections[group].map(collection => ({
          ...collection,
          kind: 'custom',
        }));
        if (!newNextToken) {
          commit(`${mutationName}`, []);
          commit(`${paginationMutation}`, null);
        }
        const oldCollections = state[list];
        commit(`${mutationName}`, [...oldCollections, ...newCollections]);
      });
      if (newNextToken && loadAll) {
        dispatch('getCustomLibraryCollectionsList', {
          nextToken: newNextToken,
          libraryId,
          workspaceId,
        });
      }
    } catch (err) {
      console.log('err getCustomLibraryCollectionsList', err);
    }
  },
  /**
   * sends request to fetch collections data
   * @param {Object} store - refernce to store
   * @param {Object} requestParams - params for request
   * @param {Function} callback - callback fn to be executed when processing is done
   * @returns
   */
  async getCollectionsList({ commit, getters, dispatch, rootState, rootGetters, state }, {
    nextTokenForRequest = null,
    limit: initLimit = 18,
    libraryId,
    isToAutocomplete = false,
    collectionGroup = 'private',
    forProduct = false,
    showSpinner: initShowSpinner = true,
    pageId = null,
    isWsPage = false,
    allowGetAllItems,
    filteredListOfCollection = [],
    accessToken = null,
    resetCancellation = false,
    comingFromRoute = null,
  } = {
  }) {
    /**
     * check if the request has been cancelled
     * this could be due to user navigating away from the page
     * cancellation state can be reset when requesting from a new page
     */
    if (resetCancellation) {
      commit('setIsCancelled', {
        group: collectionGroup,
        value: comingFromRoute,
      });
    } else {
      if (checkIfCancelled(state, collectionGroup, comingFromRoute)) return;
    }

    const showProgressBar = !rootGetters['FeatureFlags/useLazyLoading'] && !rootGetters.isShowProgressLinear;
    const useIcreaseLimit = rootGetters['FeatureFlags/getCollectionsLimit_100'] && allowGetAllItems && !isToAutocomplete;
    const showSpinner = useIcreaseLimit ? false : initShowSpinner;
    if (useIcreaseLimit && !isToAutocomplete) {
      commit('spinner', false, IS_STORE_ROOT);
      if (showProgressBar) {
        dispatch('manageProgressLinear', {
          initShow: true,
        });
      }
    }

    const INCREASED_LIMIT_NUMBER = 100;
    const limit = useIcreaseLimit ? INCREASED_LIMIT_NUMBER : initLimit;
    const getAppropriateCollectionsGroups = getters[isToAutocomplete ? 'getAppropriateCollectionsGroupsToAutocomplete' : 'getAppropriateCollectionsGroups'];
    const foundAppropriateCollections = getAppropriateCollectionsGroups.find(el => el.group === collectionGroup);
    if (!foundAppropriateCollections) {
      return;
    }
    const { mutationName, paginationMutation, list, group } = foundAppropriateCollections;
    let fetchSet = false;

    try {
      showSpinner && commit('spinner', true, IS_STORE_ROOT);
      const checkIfNotCommunity = libraryId !== 'community';
      if (!nextTokenForRequest) {
        commit(`${mutationName}`, []);
        commit(`${paginationMutation}`, null);
      }

      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      let response = null;
      let { request, requestGroup } = COLLECTION_SORT_ITEMS.find(item =>
        item.sortingMode == rootGetters['UserProfile/collectionSortingMode']) || COLLECTION_SORT_ITEMS[0];
      let useListRecentlyCollections = request == 'listResentlyCollections';

      if (checkIfNotCommunity) {
        request = !useListRecentlyCollections && requestGroup[collectionGroup] || request;
        setIsFetchingForCollectionGroup(commit, collectionGroup, true);
        fetchSet = true;
        response = await CollectionsApi[request]({
          sortByDate: true,
          limit,
          libraryId,
          workspaceId,
          ...((!requestGroup[collectionGroup] || useListRecentlyCollections) && {
            forProduct,
            collectionGroup: group,
          }),
          nextToken: nextTokenForRequest,
        });
      } else if (collectionGroup === 'suggestion') {
        useListRecentlyCollections = false;
        response = await CollectionsApi.listMyCollectionSuggestions({
          limit,
          nextToken: nextTokenForRequest,
        });
      } else if (collectionGroup === WS_PAGE) {
        response = await WorkspaceApi.listWorkspacePages({
          nextToken: nextTokenForRequest,
          accessToken,
        });
      } else {
        useListRecentlyCollections = false;
        // #HERE: Request for community
        commit('setIsFetching', {
          communityCollections: true,
        });
        response = await CollectionsApi.listCommunityCollections({
          limit,
          nextToken: nextTokenForRequest,
          workspaceId: pageId || workspaceId,
          workspaceOnly: isWsPage,
          pageId,
          accessToken,
        });
        const route = router.currentRoute;
        const { pageId: paramsPageId } = route?.params || {
        };
        commit('setIsFetching', {
          communityCollections: false,
        });
        if (paramsPageId && !isWsPage) return;
      }
      const { data } = response;
      let dataToWrite = !useListRecentlyCollections || collectionGroup === WS_PAGE
        ? data.response.data
        : data.response.data[collectionGroup];
      if (collectionGroup !== WS_PAGE) {
        dataToWrite.forEach(e => {
          if (e) e.collectionGroup = group;
        });
      }
      const { amount, nextToken } = data.response;

      // logic to sort the results
      const { ascending, sortingMode } = getters.getSortedItem;
      if (sortingMode === 'collectionName' && state.collectionGroupsForCollectionPage.includes(collectionGroup)) {
        if (ascending === true) {
          dataToWrite = dataToWrite.sort((a, b) => a.name.toLowerCase() !== b.name.toLowerCase() ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 : 0);
        } else if (ascending === false) {
          dataToWrite = dataToWrite.sort((a, b) => a.name.toLowerCase() !== b.name.toLowerCase() ? a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1 : 0);
        }
      }
      await dispatch('parseCollectionListNext', {
        data: {
          data: dataToWrite,
          amount,
          nextToken,
        },
        mutationName,
        nextTokenForRequest,
        paginationMutation,
        group,
        list,
        libraryId,
        isToAutocomplete,
        useIcreaseLimit,
        pageId,
        isWsPage,
        allowGetAllItems,
        filteredListOfCollection,
        accessToken,
        showSpinner,
        comingFromRoute,
      });
    } catch (err) {
      const allowNext = err.errors?.every(e => e.path?.includes('logo') || e.path?.includes('pictures'));
      //if error logo or picture, don't stop landing items
      if (allowNext) {
        const isArrayData = Array.isArray(err.data.response);
        const { amount, nextToken, data } = err.data.response;
        await dispatch('parseCollectionListNext', {
          data: {
            data: isArrayData ? data : data[collectionGroup],
            amount,
            nextToken,
          },
          mutationName,
          nextTokenForRequest,
          paginationMutation,
          useIcreaseLimit,
          allowGetAllItems,
          group,
          list,
          libraryId,
          pageId,
          isWsPage,
          filteredListOfCollection,
          accessToken,
          showSpinner,
          comingFromRoute,
        });
      }
      commit('spinner', false, IS_STORE_ROOT);
      console.log('getCollectionsList', err);
    } finally {
      showSpinner && commit('spinner', false, {
        root: true,
      });
      if (fetchSet) {
        setIsFetchingForCollectionGroup(commit, collectionGroup, false);
      }
    }
  },
  removeCollection({ commit, state }, item) {
    const list = state.privateCollectionsList.filter(el => el.id !== item.id);
    commit('setPrivateCollectionsList', list);
  },
  removePublishedCollection({ commit, state }, item) {
    const list = state.publishedCollectionsList.filter(el => el.id !== item.id);
    commit('setPublishedCollectionsList', list);
  },
  async getCommunityCollections({ commit, rootState, rootGetters }, { nextToken = null, limit = 18, pageId = null, pagesPerTime = 1 }) {
    const showSpinner = !rootGetters['FeatureFlags/useLazyLoading'];
    const { activeWorkspaceId: workspaceId } = rootState.Workspace;
    commit('spinner', showSpinner && true, IS_STORE_ROOT);
    commit('setIsFetching', {
      communityCollections: true,
    });
    try {
      let items = [];
      let token = nextToken;
      let page = 1;
      while (page <= pagesPerTime) {
        const {
          data = {
            response: {
            },
          },
        } = await CollectionsApi.listCommunityCollections({
          limit,
          nextToken: token,
          workspaceId: pageId || workspaceId,
          pageId,
        });
        const { response } = data ?? {
        };
        const { data: responseItems = [], nextToken = null } = response ?? {
        };
        items = [...items, responseItems].flat();
        token = nextToken;
        page += 1;
      }
      return {
        items,
        token,
      };
    } catch (e) {
      console.error('getCommunityCollections', e);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
      commit('setIsFetching', {
        communityCollections: false,
      });
    }
  },
  updateCollectionName({ commit, state, getters }, item) {
    const {
      name,
      description,
      publish,
      lastModified,
      id,
      logo,
      pictures,
      publishExpirationDate,
      publishExpirationMessage,
      location,
      author,
    } = item;
    for (const collection of getters.getListOfCollections) {
      const listName = (state[collection.list] || []).find(el => el.id === id) ? collection : null;
      if (listName) {
        let newCollectionList = state[listName.list].map(el =>
          el.id === id ? {
            ...el,
            name,
            description,
            publish,
            logo,
            pictures,
            lastModified,
            publishExpirationDate,
            publishExpirationMessage,
            location,
            author,
          } : el
        );
        commit(`${listName.mutationName}`, newCollectionList);
      }
    }
  },
  addNewCollection({ commit, state }, payload) {
    const newPrivateCollection = [payload, ...state.privateCollectionsList];
    commit('setPrivateCollectionsList', newPrivateCollection);
  },
  removePublishedFromCollections({ commit, state }, { collectionId }) {
    const filteredCollections = state.privateCollectionsList.filter(collection => collection.id !== collectionId);
    commit('setPrivateCollectionsList', filteredCollections);
  },
  sortPrivateCollectionByCreateDate({ state, commit }) {
    const { privateCollectionsList } = state;
    const arrSortByCreateDate = privateCollectionsList.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    commit('setPrivateCollectionsList', arrSortByCreateDate);
  },
  sortPublishedCollectionByCreateDate({ state, commit }) {
    const { publishedCollectionsList } = state;
    const arrSortByCreateDate = publishedCollectionsList.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    commit('setPublishedCollectionsList', arrSortByCreateDate);
  },
  async publishCollectionForCommunity({ state, dispatch, commit }, {
    collectionId,
    item,
    updateActiveListingElement,
  }) {
    try {
      if (updateActiveListingElement) {
        dispatch('updateActiveListingElement', item, IS_STORE_ROOT);
      }
      // dispatch('updateCollectionName', data.publishCollectionForCommunity);
      dispatch('removePublishedFromCollections', {
        collectionId,
      });
      const newPublishedCollections = [
        ...state.publishedCollectionsList,
        item,
      ];
      await commit('setPublishedCollectionsList', newPublishedCollections);
      await dispatch('sortPublishedCollectionByCreateDate');
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async unpublishCollectionFromCommunity({ dispatch }, {
    item,
    updateActiveListingElement,
  }) {
    try {
      if (updateActiveListingElement) {
        dispatch('updateActiveListingElement', item, IS_STORE_ROOT);
      }
      await dispatch('removePublishedCollection', item);
      await dispatch('addNewCollection', item);
      await dispatch('sortPrivateCollectionByCreateDate');
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async deleteProduct({ dispatch, state, commit, rootState }, payload) {
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      await CollectionProductsApi.deleteProduct({
        ...payload,
        workspaceId,
      });
      const { productIds } = payload;
      const newCollectionInfo = state.currentCollectionInfo.filter(el => !productIds.includes(el.id));
      dispatch('recalculateOrderingOfRows', newCollectionInfo);
      commit('clearSelectedControlProductsIds');
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  recalculateOrderingOfRows({ commit }, payload) {
    payload = payload.map((item, index) => ({
      ...item,
      order: item.order ? index + 1 : item.order,
    }));
    commit('setCurrentCollectionInfo', payload);
  },
  parseGetRows({ commit },
    {
      fields,
      index,
    }) {
    const obj = parseGetRows(fields, index, true);
    commit('setParsedObject', obj);
  },
  changesOnUpdateClick({ dispatch, commit, state }, payload) {
    dispatch('parseGetRows', {
      fields: payload,
    });
    const { parsedObject } = state;
    const { id: idFromSub = '', status = '' } = parsedObject || {
    };
    const updatedCollectionInfo = state.currentCollectionInfo.reduce((result, current) => {
      const order = result.length + 1;
      const productObj = current?.id === idFromSub ? parsedObject : current;
      const product = {
        ...productObj, order,
      };
      const isDeletedProduct = (current?.id === idFromSub) && (status === 'deleted');
      return isDeletedProduct ? result : [...result, product];
    }, []);
    commit('setCurrentCollectionInfo', updatedCollectionInfo);
  },
  async copyCollection({ commit, state, dispatch, rootState, rootGetters }, {
    collectionId,
    community = false,
    suggested = false,
    url,
  }) {
    try {
      const accessToken = getURLSearchParams({
        item: 'accessToken', url,
      }) || rootState.accessToken;
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await CollectionsApi.copyCollection({
        collectionId,
        workspaceId,
        community,
        ...(accessToken && {
          accessToken,
        }),
        suggested,
      });
      dispatch('removeSuggestionFromList', {
        id: collectionId,
      });
      if (suggested) {
        const { activeHeader } = rootState;
        commit('updateActiveElement', {
          ...activeHeader, suggested: false,
        }, IS_STORE_ROOT);
      }
      if (!rootGetters['Workspace/isPersonalWorkspace']) {
        dispatch('UserPermissions/subscribeNotifyCreatePermission', {
          resourceId: data.response.id,
          resource: COLLECTION,
        }, IS_STORE_ROOT);
      }
      const newPrivateCollection = [data.response, ...state.privateCollectionsList];
      commit('setPrivateCollectionsList', newPrivateCollection);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async manageSharedUser({ commit, state, dispatch, rootState, rootGetters }, { variables, criteria }) {
    const mappingOfShareUser = {
      createPermission: 'shareCollection',
      updatePermission: 'updateCollectionPermission',
      deletePermission: 'deleteCollectionPermission',
      inviteUsers: 'inviteUserToCollection',
    };
    try {
      commit('spinner', true, IS_STORE_ROOT);
      const { privateCollectionsList, sharedCollectionsList } = state;
      const { activeHeader } = rootState;
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await CollectionsApi[mappingOfShareUser[criteria]]({
        ...variables,
        workspaceId,
      });
      const collection = data.response;
      dispatch('Workspace/manageInvitedUsersForShareWorkspace', {
        variables,
        members: collection.members,
        criteria,
      }, IS_STORE_ROOT);
      if (criteria == 'deletePermission' && variables.targetUserId == rootGetters.userId) {
        commit('setSharedCollectionsList', sharedCollectionsList.filter(item => collection.id !== item.id));
      } else {
        commit('setPrivateCollectionsList', [
          ...privateCollectionsList.map(item => (
            collection.id === item.id ? {
              ...item,
              members: collection.members,
            } : item
          )),
        ]);
      }
      commit('updateActiveElement', {
        ...activeHeader,
        members: collection.members,
      }, IS_STORE_ROOT);
      const route = router.currentRoute;
      if (route.name == 'collection-library' && criteria == 'deletePermission' && variables.targetUserId == rootGetters.userId) {
        router.push({
          name: 'collections',
          params: {
            id: route.params.libraryId || rootGetters['Libraries/getDefaultLibraryId'],
          },
        });
      }
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async setReadCollectionView({ dispatch, state, commit, rootState }, {
    useSpinner = true,
    showProductDialog = true,
    collectionName = '',
    collectionLink = '',
    ...payload
  }) {
    try {
      if (useSpinner) commit('spinner', true, IS_STORE_ROOT);
      const { editable, rowId: id, tableId: collectionId, mode = '' } = payload;
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await ComparisonApi.getRowDiffProduct({
        ...payload,
        workspaceId,
      });
      dispatch('parseGetRows', {
        fields: data.response.fields,
      });
      const { parsedObject } = state;
      const objForRendering = {
        ...parsedObject,
        mode,
        SK: `ROW#${payload.rowId}`,
        id,
        collectionId,
        editable,
      };
      commit('setProductToUpdate', objForRendering);
      if (showProductDialog) {
        commit('changeProductModalVariant', TYPE_READONLY);
        commit('changeUpdateProductMode', true);
        commit('changeProductCollectionName', collectionName);
        commit('changeProductCollectionLink', collectionLink);
      }
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      if (useSpinner) commit('spinner', false, IS_STORE_ROOT);
    }
  },
  unfollowCollection({ state, commit }, payload) {
    const { collection, list } = payload;
    let newFollowedList = [];
    if (['collections'].includes(list)) {
      newFollowedList = state.followedCollectionsList.filter(el => el.id !== collection.id);
      commit('setFollowedCollectionsList', newFollowedList);
    } else if (['community-collections'].includes(list)) {
      newFollowedList = state.communityCollections.map(el =>
        el.id === collection.id ? {
          ...el,
          follow: collection.follow,
        } : el
      );
      commit('setCommunityCollections', newFollowedList);
    }
  },
  async removeFromLibrary({ rootState, commit, dispatch, getters }, {
    group,
    libraryId,
    collectionId,
  }) {
    commit('spinner', true, IS_STORE_ROOT);
    try {
      await CollectionsApi.removeCollectionFromLibrary({
        collectionId,
        libraryId,
        workspaceId: rootState.Workspace.activeWorkspaceId,
      });
      const { mutationName, list } = getters.getGroupInfo(group);
      const removeList = getters.collectionList(list);
      commit(mutationName, removeList.filter(({ id }) => id != collectionId));
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async intersectCollections({ state, commit, dispatch, getters }, {
    entries,
    list,
    collectionGroup,
    pagination,
    libraryId,
  }) {
    const collectionId = entries[0].target.id;
    const { nextToken: nextTokenForRequest } = state[pagination];
    const lastCollectionId = getters.collectionList(list)[state[list].length - 1].id;
    if (collectionId === lastCollectionId
      && nextTokenForRequest
    ) {
      commit('spinner', true, IS_STORE_ROOT);
      await dispatch('getCollectionsList',
        {
          nextTokenForRequest,
          collectionGroup,
          libraryId,
        });
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  /**
   * CollectionsCreateProductDialog component must be created
   * @param state
   * @param commit
   * @param rootGetters
   */
  openCreateProductModal({ commit, rootGetters }) {
    if (!rootGetters['UserRoles/canAddProductToCollection'](rootGetters['UserRoles/findUserRoleInLibraryForActiveHeader']).allowed) {
      return;
    }
    commit('changeProductModalVariant', TYPE_CREATE);
    commit('changeUpdateProductMode', true);
  },
  /**
   * CollectionsCreateProductDialog component must be created
   * @param state
   * @param commit
   * @param item
   */
  openUpdateProductModal({ commit }, item) {
    commit('changeProductModalVariant', TYPE_READONLY);
    commit('changeUpdateProductMode', true);
    commit('setProductToUpdate', cloneDeep(item));
  },
  async getlistCollectionsForProduct({ commit, dispatch, rootState }, { projectId }) {
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await CollectionsApi.listCollectionsForProduct({
        projectId,
        workspaceId,
      });
      commit('setProjectSourceLibraryCollections', data.response.projectSourceLibraryCollections);
      commit('setUserCollections', data.response.userCollections);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async getCollectionSuggestions({ dispatch, commit }, { limit, nextToken } = {
  }) {
    try {
      commit('setIsFetching', {
        collectionSuggestions: true,
      });
      const { data } = await CollectionsApi.listMyCollectionSuggestions({
        limit,
        nextToken,
      });
      return data;
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    } finally {
      commit('setIsFetching', {
        collectionSuggestions: false,
      });
    }
  },
  async ignoreSuggestion({ dispatch, commit }, { id: suggestionId = null } = {
  }) {
    try {
      commit('spinner', true, IS_STORE_ROOT);
      await CollectionsApi.ignoreSuggestion({
        suggestionId,
      });
      dispatch('removeSuggestionFromList', {
        id: suggestionId,
      });
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  removeSuggestionFromList({ state, commit }, { id }) {
    const { collectionSuggestionsList: list } = state;
    const filtered = list.filter(c => c.id !== id);
    commit('setCollectionSuggestionsList', filtered);
  },
  async getCollectionSharingOptions({ dispatch, commit }, {
    id: collectionId = null,
    useIam = false,
    accessToken = undefined,
    showSpinner = true,
  } = {
  }) {
    try {
      if (showSpinner) {
        commit('spinner', true, {
          root: true,
        });
      }
      const { data } = await CollectionsApi.getCollectionSharingOptions({
        collectionId,
        accessToken,
      }, useIam);
      commit('setCurrentCollectionSharingOption', data.response);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      if (showSpinner) {
        commit('spinner', false, {
          root: true,
        });
      }
    }
  },
  async switchTypeSharing({ commit, dispatch }, { type, collectionId, enabled = true }) {
    try {
      commit('spinner', true, {
        root: true,
      });
      const { data } = await CollectionsApi.switchTypeSharing({
        collectionId,
        type,
        enabled,
      });
      commit('setCurrentCollectionSharingOption', data?.response);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, {
        root: true,
      });
    }
  },
  async getRolesInCurrentCollection({ commit, dispatch, rootGetters }, { workspaceId, resourceId }) {
    const useSpinner = !rootGetters['FeatureFlags/useLazyLoading'];
    try {
      if (useSpinner) commit('spinner', true, IS_STORE_ROOT);
      const { data } = await Api.getMyRolesInResource({
        workspaceId,
        resourceType: COLLECTION,
        resourceId,
      });
      const { roles = [] } = data.response || {
      };
      commit('setArrRoles', roles, IS_STORE_ROOT);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      if (useSpinner) commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async updateCollectionColumnSize({ dispatch, getters }, { variables, updateBefore = false }) {
    try {
      if (!updateBefore) {
        const { data } = await ProjectViewsApi.updateViewColumnsSize(variables);
        dispatch('updateCollectionView', {
          updatedView: {
            columnsSize: data.response.columnsSize,
          },
        });
        return;
      }
      const newColumnsSize = variables.columnsSize;
      const { selectedCollectionView } = getters;
      const { columnsSize } = selectedCollectionView;
      const updateColumns = columnsSize.map(col => {
        const changedCol = newColumnsSize.find(newCol => newCol.id == col.id);
        if (changedCol) {
          return {
            ...col,
            size: changedCol.size,
          };
        }
        return col;
      });
      dispatch('updateCollectionView', {
        updatedView: {
          columnsSize: updateColumns,
        },
      });
      ProjectViewsApi.updateViewColumnsSize(variables);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async suggestCollection({ commit, dispatch }, {
    selectedUsers = [],
    emailMessage = '',
    collectionId = null,
    force = false,
  } = {
  }) {
    const extractEmail = (user) => {
      return typeof user === 'string' ? user : user?.email;
    };
    commit('spinner', true, {
      root: true,
    });
    await Promise.allSettled(selectedUsers.map(async user => {
      await CollectionsApi.suggestCollection({
        collectionId,
        email: extractEmail(user),
        emailMessage,
        force,
      });
    })).then((res) => {
      let emailsToAlreadyExist = [];
      const errors = res.reduce((result, option, index) => {
        if (option.status === PROMISE_STATUSES.REJECTED) {
          const { message = null, errorType = null } = option?.reason?.errors?.[0];
          if (message) {
            const user = selectedUsers[index];
            const email = extractEmail(user);
            if (errorType === 'Suggestion Exists') {
              emailsToAlreadyExist = [...emailsToAlreadyExist, email];
              return result;
            }
            const errorMessageToRendering = `${message}(${email})`;
            return [...result, errorMessageToRendering];
          }
        }
        return result;
      }, []);
      if (!errors.length) {
        commit('setSuggestionErrorSnackbar', {
          open: false,
        });
      }
      if (errors.length) {
        commit('openSnackBar', {
          text: errors,
          timeout: 5000,
        }, IS_STORE_ROOT);
      }
      if (emailsToAlreadyExist.length) {
        commit('setSuggestionErrorSnackbar', {
          open: true,
          data: {
            users: emailsToAlreadyExist,
            emailMessage,
            collectionId,
          },
        });
      }
    }).catch((err) => {
      dispatch('handleError', err, IS_STORE_ROOT);
    }).finally(() => {
      commit('spinner', false, {
        root: true,
      });
    });
  },
  updateCollectionView({ commit, getters }, { updatedView }) {
    const { selectedCollectionView } = getters;
    commit('setCollectionsViews', [{
      ...selectedCollectionView,
      ...updatedView,
    }]);
  },
  limitOfProductsInCollectionError({ dispatch }) {
    const text = `Collections are limited to max of 100 products. Please <a target="_blank" href=${NEW_FEATURES_AND_FEEDBACK}>contact support</a>`;
    dispatch('handleError', text, IS_STORE_ROOT);
  },
  async subscribeImportDocument({ dispatch, rootState }, variables) {
    try {
      const SUBSCRIPTION_TITLE = 'subscribeImportDocument';
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const subscription = await CollectionsApi.subscribeImportDocument({
        ...variables,
        workspaceId,
      }).subscribe({
        next: ({ value }) => {
          const {
            collectionId,
            errors,
            processedCount,
            status,
          } = value.data.response;
          const { FINISHED, FAILED } = UPLOAD_STATUSES;
          const unsubscribeStatuses = [FINISHED, FAILED];
          if (status === FAILED && errors.length) {
            dispatch('handleError', errors[0], IS_STORE_ROOT);
            return;
          }
          if (status === FINISHED) {
            const title = processedCount > 0 ? `${processedCount} product(s) have been successfully uploaded to the collection`
              : 'No products added to the collection';
            dispatch('handleError', title, IS_STORE_ROOT);
          }
          if (unsubscribeStatuses.includes(status)) {
            dispatch('ManageSubscriptions/removeSubscriptions', `${SUBSCRIPTION_TITLE}_${collectionId}`, IS_STORE_ROOT);
          }
        },
      });
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: `${SUBSCRIPTION_TITLE}_${variables.collectionId}`,
      }, IS_STORE_ROOT);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  updateUnreadComments({ commit, state, dispatch }, { updatedCollectionId, commentsUnread }) {
    try {
      const { privateCollectionsList, sharedCollectionsList } = state;
      commit('setSharedCollectionsList', sharedCollectionsList.map(item => updatedCollectionId == item.id ? {
        ...item,
        commentsUnread,
      } : item));
      commit('setPrivateCollectionsList', privateCollectionsList.map(item => updatedCollectionId == item.id ? {
        ...item,
        commentsUnread,
      } : item));
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  async subscribeOnProductUpdate({ dispatch }, variables) {
    try {
      const nextSub = ({ data }) => {
        const { fields = [] } = data?.response || {
        };
        dispatch('changesOnUpdateClick', fields);
      };
      const subscription = subscribeOnProductUpdate(variables, nextSub);
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: ON_PRODUCT_UPDATE,
      }, IS_STORE_ROOT);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async subscribeCollectionsAccess({ rootState, dispatch, commit }) {
    try {
      const workspaceId = rootState.Workspace.activeWorkspaceId;
      const subscription = await subscribeForCollectionsAccess({
        workspaceId,
      }, ({ data }) => {
        const { response } = data;
        const collectionsList = rootState.Collections.privateCollectionsList.map(col => {
          if (col.id == response.id) {
            return {
              ...col,
              members: response?.members,
            };
          }
          return col;
        });
        commit('Collections/setPrivateCollectionsList', collectionsList, IS_STORE_ROOT);
      });
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: COLLECTION_ACCESS,
      }, IS_STORE_ROOT);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  async showCollectionInfo({ commit, dispatch }, { isCommunityCollections, collectionId } = {
  }) {
    commit('spinner', true, {
      root: true,
    });
    await dispatch('getMyRoleInResource', {
      resourceId: collectionId,
    }, IS_STORE_ROOT);
    const criteria = isCommunityCollections ? 'getCommunityCollectionInfo' : 'getLibraryCollectionInfo';
    await dispatch('getInfo', {
      collectionId,
      criteria,
      windowStatus: 'update',
      queryType: 'Collection',
    }, IS_STORE_ROOT);
    commit('openInfoModal', true, IS_STORE_ROOT);
    commit('spinner', false, {
      root: true,
    });
  },
  searchProducts({ commit }, data) {
    const items = new SearchProductsKeys().setFormOfSearchingKeys(data);
    commit('setKeysToSearchingProducts', items);
  },
  filterDeletedProductsFromSearch({ state, commit }, productsIds) {
    const { itemsFromCollectionSearch: sections } = state;
    const PRODUCT_TITLE = 'Products';
    const filtered = sections.reduce((result, current) => {
      const { title = '', dataArr: products = [] } = current || {
      };
      let section = current;
      const isProducts = title === PRODUCT_TITLE;
      if (isProducts) {
        const dataArr = products.filter(e => !productsIds.includes(e?.id));
        section = {
          ...section, dataArr,
        };
      }
      return [...result, section];
    }, []);
    commit('setItemsFromCollectionSearch', filtered);
  },
  async deleteProductFromSearch({ commit, rootState, dispatch }, payload) {
    try {
      const { productIds = [] } = payload || {
      };
      commit('spinner', true, IS_STORE_ROOT);
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      await CollectionProductsApi.deleteProduct({
        ...payload,
        workspaceId,
      });
      dispatch('filterDeletedProductsFromSearch', productIds);
      commit('changeUpdateProductMode', false);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  manageProgressLinear({ commit, dispatch, state }, { collectionGroups = [], initShow = false } = {
  }) {
    if (initShow) {
      dispatch('setProgressLinearToCollections', {
        count: 0,
      });
      return;
    }
    const isLoading = collectionGroups?.some(({ paginationState }) => !!state[paginationState]?.nextToken);
    if (!isLoading) {
      commit('closeProgressLinear', null, IS_STORE_ROOT);
      return;
    }
    const collectionsCount = collectionGroups.reduce((result, option) => {
      const { list } = option || {
      };
      if (!list) return result;
      const { [list]: collectionsList = [] } = state;
      const count = collectionsList?.length;
      if (!count) return result;
      return result + count;
    }, 0);
    dispatch('setProgressLinearToCollections', {
      count: collectionsCount,
    });
  },
  setProgressLinearToCollections({ commit }, { title = 'Getting Collections', count = 0 } = {
  }) {
    commit('setProgressLinear', {
      title: `${title} (${count})`,
    }, IS_STORE_ROOT);
  },

  async subscribeCollectionFollowAndUnfollow({ rootState, dispatch, commit, getters }) {
    try {
      const workspaceId = rootState.Workspace.activeWorkspaceId;

      const followSub = subscribeToCollectionFollow({
        workspaceId: workspaceId,
      }, (resp) => {
        const coll = resp?.data?.response;
        if (!coll) return;

        coll.collectionGroup = GROUP_FOLLOWED;
        const newFollowed = uniqBy([...getters.getFollowedCollectionsList, coll], 'id');
        commit('setFollowedCollectionsList', newFollowed);
      });

      dispatch('ManageSubscriptions/setSubscription', {
        followSub,
        title: FOLLOW_COLL_SUB_TITLE,
      }, IS_STORE_ROOT);

      const unfollowSub = subscribeToCollectionUnfollow({
        workspaceId: workspaceId,
      }, (resp) => {
        console.log('unf', resp);
        const coll = resp?.data?.response;
        if (!coll) return;

        const newFollowed = getters.getFollowedCollectionsList.filter((item) => item.id != coll.id);
        commit('setFollowedCollectionsList', newFollowed);
      });

      dispatch('ManageSubscriptions/setSubscription', {
        unfollowSub,
        title: UNFOLLOW_COLL_SUB_TITLE,
      }, IS_STORE_ROOT);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  async unsubscribeCollectionFollowAndUnfollow({ dispatch }) {
    try {
      dispatch('ManageSubscriptions/removeSubscription', {
        title: FOLLOW_COLL_SUB_TITLE,
      }, IS_STORE_ROOT);
      dispatch('ManageSubscriptions/removeSubscription', {
        title: UNFOLLOW_COLL_SUB_TITLE,
      }, IS_STORE_ROOT);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  /**
   * Scraped product data from a url.
   * @param url: string
   *
   * Returns:
   * - productData obj (the kind after parsing UniversalRows return type).
   * - { field: value } for each productHeader.
   */
  async scrapeProductData({ commit, rootState, rootGetters }, { url, abortController }) {
    try {
      commit('setIsFetching', {
        scrapingProductData: true,
      });

      commit('openSnackBar', {
        title: 'Importing data from link',
        timeout: 1200,
      }, IS_STORE_ROOT);

      // Grab an id for attachments/images. Required to upload to S3.
      // We need to get an id here, because we need to subscribe to image upload and thumbnail generation.
      let attachmentId = '';

      const { data: attachmentData } = await Api.declareUserAttachement(abortController);
      const { id } = attachmentData.response;
      if (!id) {
        throw new Error('No attachmentId');
      } else {
        attachmentId = id;
      }

      // If image upload + thumbnail generation is too slow, we skip and assume it failed.
      const thumbnailUpdatePromise = subscribeToThumbnailUpdate(attachmentId);
      const oneMinutePromise = new Promise((_, reject) => {
        const timeoutId = setTimeout(() => {
          reject(new Error('Subscription callback timed out'));
        }, 60000);
        abortController?.signal.addEventListener('abort', () => {
          clearTimeout(timeoutId);
          reject(new Error('Aborted'));
        });
      });

      const thumbnailOrTimeoutPromise = Promise.race([thumbnailUpdatePromise, oneMinutePromise]);

      const identityId = rootGetters['UserProfile/identityId'];
      const { activeWorkspaceId } = rootState.Workspace;
      const scrapePromise = ProductsApi.scrapeProductData({
        url: url,
        attachmentId: attachmentId,
        identityId: identityId,
        workspaceId: activeWorkspaceId,
        abortController: abortController,
      });

      const [subResponse, scrapeResponse] = await Promise.allSettled([thumbnailOrTimeoutPromise, scrapePromise]);
      if (scrapeResponse.status === 'rejected') {
        console.error(scrapeResponse.reason);
        throw new Error('Error when getting data from url');
      }

      // Placed at the end of the promises - below this is all synchronous, instant code.
      if (abortController?.aborted()) {
        console.log('Scrape request aborted');
        return;
      }

      const { data } = scrapeResponse.value;
      const product = data?.response?.data?.[0]?.fields;
      if (!product) {
        throw new Error('No product data received from scraping!');
      }
      if (subResponse.status === 'rejected') {
        console.warn('Error or slow Image processing, will not fill images', subResponse.reason);
        product[PRODUCT_HEADERS.IMAGE] = undefined;
      }
      return parseGetRows(product);

    } finally {
      commit('setIsFetching', {
        scrapingProductData: false,
      });
    }
  },
};

async function subscribeToThumbnailUpdate(attachmentId) {
  return new Promise((resolve, reject) => {
    try {
      SubscriptionsAPI.subscribeThumbnailsUpdate({
        id: attachmentId,
      }).subscribe({
        next: () => {
          resolve(attachmentId);
        },
      });
    } catch (err) {
      reject(err);
    }
  });
}

function setIsFetchingForCollectionGroup(commit, group, isFetching) {
  switch (group) {
  case COLLECTIONS_WORKSPACE_GROUP:
    commit('setIsFetching', {
      workspaceCollections: isFetching,
    });
    break;
  case COLLECTIONS_PUBLISHED_GROUP:
    commit('setIsFetching', {
      publishedCollections: isFetching,
    });
    break;
  case COLLECTIONS_FOLLOWED_GROUP:
    commit('setIsFetching', {
      followedCollections: isFetching,
    });
    break;
  }
}

function checkIfCancelled(state, group, routeName) {
  let val;

  switch (group) {
  case COLLECTIONS_WORKSPACE_GROUP:
    val = state.isCancelled.workspaceCollections;
    break;
  case COLLECTIONS_PUBLISHED_GROUP:
    val = state.isCancelled.publishedCollections;
    break;
  case COLLECTIONS_FOLLOWED_GROUP:
    val = state.isCancelled.followedCollections;
    break;
  }

  // Collections/Community Listing are NOT the only places where you fetch collections.
  // [https://app.asana.com/0/1205146388025688/1207564723768369]
  // - It's used for fetching collections for products to follow + other
  //   locations where you need to list collections for other reasons.
  // - We CANNOT use routeName for this purpose. So assume null routeName = uncancelled.
  if (!routeName) return false;

  return val !== routeName;
}
